import { AES_SECRET } from '@/misc/constants';
import { hashData } from '@/misc/utils';

const mixin = {
    data () {
        return {
            userSearchedObject: {}
        };
    },
    created () {
        this.setImportedConstants();
    },
    methods: {
        setImportedConstants () {
          this.$options.AES_SECRET = AES_SECRET;
        },
        getUserProfileRoute (user, selectedTab) {
            const hashedId = hashData(user.id, this.$options.AES_SECRET);
            const queryParams = {};

            if (selectedTab) {
                queryParams.selectedTab = selectedTab;
            }

            return {
                name: 'social.user-profile',
                params: { query: `${user.username}-${hashedId}` },
                query: queryParams
            };
        },
        goToUserProfile (user, selectedTab, newTab) {
            const route = this.getUserProfileRoute(user, selectedTab);

            if (newTab) {
                const routeData = this.$router.resolve(route);
                window.open(routeData.href, '_blank');
            } else {
                this.$router.push(route)
                .then(failure => {
                    console.log(failure);
                });
            }
        },
        // probably not in use
        getUserProfileUrl (user) {
            const hashedId = hashData(user.id, this.$options.AES_SECRET);
            return window.location.origin + `/perfil/${user.username}-${hashedId}`;
        }
    }
};

export default mixin;
